import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './10k-referral-contest.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <Link to="/referrals/">
            <Img
              fluid={fluid}
              className={styles.aboutImageWrapper}
              alt="$10k referral contest"
            />
          </Link>
          <h2 className={styles.header}>
            Congratulations to the winners of our
          </h2>
          <h1 className={styles.gigantor}>$10k Referral Giveaway</h1>
          <p className={`content`}>
            Many Trustaff travelers have found their way to us through a kind
            word from a friend or colleague, and we'll <b>reward you</b> for the
            good deed!
            <br />
            <br />
            Between March 1st and <b>August 31st</b>, each successful referral
            not only earns you a{' '}
            <Link to="/referrals/">
              <b>referral bonus</b>
            </Link>
            , it also enters you and your friend to win more than{' '}
            <b>$10,000 in prizes!</b>
          </p>
          <Link to="/referrals/" className="button is-primary">
            Refer a friend
          </Link>
          <h3 className={styles.subheader}>
            Help your friend find their dream job—each successful referral
            between March 1st and August 31st, 2020 enters you to win!
          </h3>
          <div className={`section ${styles.referrals}`}>
            <p className={`content has-text-centered-mobile ${styles.bullets}`}>
              <b>$10k referral giveaway rules:</b>
              <br />
              • No limit to the number of referrals you can complete
              <br />
              • Referred friend also gets prize money
              <br />
              • One successful referral = one entry
              <br />
              • One successful Ohio referral = TWO entries
              <br />• Contest ends August 31st, 2020
            </p>
          </div>
          <p className={`content ${styles.prize1}`}>$5,000 GRAND PRIZE</p>
          <p className={`content ${styles.prize3}`}>
            $3,500 for you, $1,500 for your friend
          </p>
          <hr />
          <p className={`content ${styles.prize3}`}>$3,000 SECOND PLACE</p>
          <p className={`content ${styles.prize4}`}>
            $2,000 for you, $1,000 for your friend
          </p>
          <hr />
          <p className={`content ${styles.prize3}`}>$2,000 THIRD PLACE</p>
          <p className={`content ${styles.prize4}`}>
            $1,500 for you, $500 for your friend
          </p>
          <hr />
          <p className={`content ${styles.prize5}`}>
            4-10th place both you and your friend receive a truGear Prize Pack!
          </p>
          <Link to="/referrals/" className="button is-primary">
            Learn more about referrals
          </Link>
          <div className={`section ${styles.icons}`}>
            <p className={`content ${styles.restrictions}`}>
              *Giveaway ends August 31, 2020. All referral bonuses are paid
              within 30 days of the referred employee completing their
              assignment. Restrictions may apply, ask your recruiter for
              complete details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
